.botaoLogo {
  padding: 0;
  width: fit-content;
}

.corTerciaria {
  background-color: var(--cor-terciaria) !important;
}

.paginaAplicativos {
  & > img {
    width: 85px;
    height: auto;
  }
}

.submenu {
  z-index: 3;
}

.icone {
  width: 85px;
}


@media screen and (max-width: 800px) {
  .nomeDoUsuario { 
    display: none;
  }

  .submenu {
    right: 0;
    width: auto !important;
  }
}
