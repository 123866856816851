body {
  background-color: #f1f1f1;
}

a {
  cursor: pointer;
}


:root {
  --cor-primaria: #1e70b8;
  --cor-primaria-h: 208;
  --cor-primaria-s: 72%;
  --cor-primaria-l: 42%;
  --cor-secundaria: #1bb34e;
  --cor-secundaria-h: 140;
  --cor-secundaria-s: 74%;
  --cor-secundaria-l: 40%;
  --cor-terciaria: #094f9a;
  --cor-terciaria-h: 207;
  --cor-terciaria-s: 86%;
  --cor-terciaria-l: 20%;
}
